.main-container {
    width: calc(100% - #{$navagationWidth});
    margin-left: $navagationWidth;
    transition: width 600ms, margin-left 600ms;
    @media (max-width: $breakpoint-md) {
        width: 100%;
        margin-left: 0;
        .main-container-spacing {
            padding: 20px 30px 20px 80px;
        }
    }
}
// 通知畫面用
//.main-notify-container {
//    width: calc(100% - #{$navagationWidth});
//    margin-left: $navagationWidth;
//    transition: width 600ms, margin-left 600ms;
//}

.main-container-spacing {
    padding: 20px 30px;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}