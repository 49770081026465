.checkin-table-checkin-time-label {
    margin-bottom: -10px;
    .checkin-count {
        background: $actionDisabledBackground;
        border-radius: $shapeBorderRadius;
        width: 40px;
        margin-bottom: 8px;
    }
}

.checkin-dialog {
    .checkin-table-header {
        background: $primaryColorMain;
        > .table-cell {
            color: #fff;
        }
    }
    .checkin-button {
        height: 50px;
        margin-top: 16px;
    }
}
