.formhelpertext {
    @include mxVariants('body2');
    color: $textSecondaryColor;
    text-align: left;
    margin: 0.6rem 0 0 0;
    &#{&}-disabled {
        color: $textDisabledColor;
    }
    &#{&}-error {
        color: $errorColorMain;
    }
    &#{&}-size-small {
        margin-top: 0.6rem;
    }
    &#{&}-contained {
        margin-left: 1.4rem;
        margin-right: 1.4rem;
    }

    &#{&}-security {
        svg {
            vertical-align: middle;
            margin-right: 0.2rem;
            fill: $fontColor-4;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            margin-right: 1rem;
            color: $fontColor-4;
        }
    }

    #{&}-security-active {

        svg {
            fill: $successColorDarken;
        }

        span {
            color: $textSecondaryColor;
        }

    }
}
