/*
    文字對齊
 */
$alignMap: center, justify, left, right;
.text {
    @each $align in $alignMap {
        &-#{$align} {
            text-align: $align !important;
        }
    }
}

/*
    字重
 */
.font-weight-normal {
    font-weight: $fontWeightRegular !important;
}
.font-weight-medium {
    font-weight: $fontWeightMedium !important;
}
.font-weight-bold {
    font-weight: $fontWeightBold !important;
}
.font-weight-900 {
    font-weight: 900 !important;
}

/*
    文字標記
 */
.mark {
    letter-spacing: 0.12rem;
    font-weight: $fontWeightMedium;
    display: inline-block;
    padding: 0.2rem 0.8rem;
    margin: 0.8rem;
    position: relative;
    z-index: 10;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: $markColor;
        z-index: -1;
    }
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.vertical-align-top {
    vertical-align: top !important;
}

/*
    字大小
 */
.font-size-small {
    font-size: .8em !important;
}

.font-size-xs {
    font-size: .7em !important;
}

.font-size-xxs {
    font-size: .6em !important;
}