.landing-page {
    .sticky-header {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: $page-bg;
        margin: 0 -5px;
        padding: 0 5px;
    }
    .tabs-root {
        .tab-root {
            min-width: 14rem;
            border-radius: 10px 10px 0 0;
            padding: 2rem 2.4rem;
            margin-right: 1.2rem;
            font-size: 1.8rem;
            letter-spacing: 1px;
            font-weight: $fontWeightMedium;
            color: $fontColor-4;
            background-color: rgba(0, 0, 0, 0.05);
            &.active {
                color: $primaryColorMain;
                background-color: white;
            }
        }
        .tabs-indicator {
            transform: scaleX(0.8);
        }
    }
    .sticky-card-header {
        position: sticky;
        top: 75px;
        background: white;
        z-index: 10;
        &.checkin {
            top: 62px;
        }
    }
}

.interview-not-measure {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
}
