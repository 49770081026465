.business-card-box {
    overflow: hidden;
    &-content {
        padding-left: 1.2rem;
        .privacy-data__content {
            font-weight: 500;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .member-box-name-label {
        font-size: $fontSize;
    }
}
